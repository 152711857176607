import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import { EventModel, InventoryModel, PageableModel, SimilarInventoryInfo } from '@shared/models';
import { IDUTChanges, IEventAssignmentsDB } from '@shared/interfaces/offline';
import { OfflineService } from '@services/offline/offline.service';
import { filter, take } from 'rxjs';
import { Wait } from '@shared/utils/wait';
import { IEventAssignedInventoryResponse } from '@shared/interfaces/events/event-assigned-inventory-responce';

@Injectable({
  providedIn: 'root'
})
export class HandleRequestsService {
  constructor(private offlineService: OfflineService) {}

  async getOfflineData(request: HttpRequest<unknown>): Promise<any> {
    const url: string = request.url;
    // Use to add a pause to return http response in interceptor for async requests
    await Wait();
    if (!this.offlineService.isOfflineReady$.value) {
      const observable = this.offlineService.isOfflineReady$.asObservable();
      await new Promise<void>(resolve => {
        observable
          .pipe(
            filter(value => value === true),
            take(1)
          )
          .subscribe({
            complete: () => {
              resolve();
            }
          });
      });
    }
    if (request.method === 'GET') {
      if (url.endsWith('inventories/pageable/v1')) {
        return new HttpResponse({ status: 200, body: this.getAllInventories(request) });
      } else if (url.endsWith('events/pageable/v1')) {
        await Wait(100);
        return new HttpResponse({ status: 200, body: this.getEventsPageable(request) });
      } else if (
        url.includes('alerts/pageable') ||
        url.includes('inventory/actions/pageable') ||
        url.includes('core/transfers') ||
        url.includes('inventory/inspections/') ||
        url.includes('/inventoryRequests') ||
        url.includes('emailHistory/pageable') ||
        (url.includes('/users/') && url.includes('/organizations'))
      ) {
        return new HttpResponse({ status: 200, body: [] });
      } else if (url.includes('/users/me')) {
        return new HttpResponse({ status: 200, body: this.offlineService.offlineUser });
      } else if (
        url.endsWith('/images') ||
        (url.includes('catalogs/') && url.endsWith('/products')) ||
        url.endsWith('/attachment') ||
        url.endsWith('/attachments')
      ) {
        return new HttpResponse({ status: 200, body: [] });
      } else if (url.split('events/')[1]?.length === 36) {
        const eventId = this.getIdFromRequestUrl(url);
        const event = this.offlineService.offlineEventAssignments.get(eventId).event;
        return new HttpResponse({ status: 200, body: event });
      } else if (url.endsWith('/eventContainers')) {
        return new HttpResponse({
          status: 200,
          body: this.offlineService.offlineEventAssignments.get(this.getIdFromRequestUrl(url)).eventContainers
        });
      } else if (url.includes('events/') && url.endsWith('/manufacturers')) {
        return new HttpResponse({
          status: 200,
          body: this.offlineService.offlineEventAssignments.get(this.getIdFromRequestUrl(url)).manufacturers
        });
      } else if (url.includes('events/') && url.endsWith('/products')) {
        return new HttpResponse({
          status: 200,
          body: this.offlineService.offlineEventAssignments.get(this.getIdFromRequestUrl(url)).products
        });
      } else if (url.includes('events/') && url.endsWith('/notes')) {
        return new HttpResponse({
          status: 200,
          body: this.offlineService.offlineEventAssignments.get(this.getIdFromRequestUrl(url)).notes
        });
      } else if (url.includes('events/') && url.endsWith('/ponumber')) {
        return new HttpResponse({
          status: 200,
          body: this.offlineService.offlineEventAssignments.get(this.getIdFromRequestUrl(url)).poNumbers
        });
      } else if (url.endsWith('/priceAdjustments')) {
        return new HttpResponse({
          status: 200,
          body: this.offlineService.offlineEventAssignments.get(this.getIdFromRequestUrl(url)).priceAdjustments
        });
      } else if (url.includes('events/') && url.endsWith('/inventories')) {
        return new HttpResponse({ status: 200, body: this.getEventInventories(url) });
      } else if (url.split('inventories/')[1]?.length === 36) {
        await Wait();
        return new HttpResponse({
          status: 200,
          body: this.offlineService.offlineInventories.get(this.getIdFromRequestUrl(url, '/inventories/'))
        });
      } else if (url.includes('/event-tracking-action/')) {
        return new HttpResponse({ status: 200, body: null });
      }
    } else if (request.method === 'POST') {
      if (url.includes('/inventories/search')) {
        /** Grouped SimilarInventory*/
        // @ts-ignore
        const devices: SimilarInventoryInfo[] = await this.getSimilarInventoryData(
          this.getIdFromRequestUrl(url),
          //@ts-ignore
          request.body.searchText,
          //@ts-ignore
          request.body?.toExclude
        );

        return new HttpResponse({ status: 200, body: devices });
      } else if (url.endsWith('inventories/assign')) {
        // @ts-ignore
        await this.offlineService.trackChanges('markUsedInventory', this.getIdFromRequestUrl(url), { markUsed: request.body });
        return new HttpResponse({ status: 200, body: [] });
      } else if (url.endsWith('dut/submit')) {
        // @ts-ignore
        await this.offlineService.trackChanges('closeEvent', this.getIdFromRequestUrl(url), { closeEvent: request.body });
        return new HttpResponse({ status: 200, body: {} });
      } else if ((url.includes('events/') && url.endsWith('inventories/bulk-unassign')) || url.endsWith('reusable-inventories/remove')) {
        // @ts-ignore
        await this.offlineService.trackChanges('removeDeviceFromEvent', this.getIdFromRequestUrl(url, '/events/'), {
          // @ts-ignore
          unAssignInventoryIds: request.body
        });
        return new HttpResponse({ status: 200, body: {} });
      }
    }
    return new HttpResponse({ status: 200, body: {} });
  }

  getEventInventories(url: string): IEventAssignedInventoryResponse {
    const eventId: string = this.getIdFromRequestUrl(url);
    let assignedDevices: InventoryModel[] = this.offlineService.offlineEventAssignments.get(eventId)?.devices ?? [];
    let assignedPacks: InventoryModel[] = this.offlineService.offlineEventAssignments.get(eventId)?.packs ?? [];

    const markUsedInventory = this.offlineService.userChanges.markUsedInventory;
    const markUsedInventoryInd = markUsedInventory.findIndex(e => e.eventId === eventId);
    const removeDeviceFromEvent = this.offlineService.userChanges.removeDeviceFromEvent;
    const removeDeviceFromEventInd = removeDeviceFromEvent.findIndex(e => e.eventId === eventId);
    // Hide removed devices during offline mode
    // Add new marked devices during offline mode
    if (markUsedInventoryInd !== -1) {
      const allNewMarkedInventories: InventoryModel[] = this.offlineService.getEventInventoriesByIds(
        markUsedInventory[markUsedInventoryInd].inventoryIds
      );
      const newMarkedDevices = allNewMarkedInventories.filter(i => i.inventoryType === 'DEVICE');
      const newMarkedPacks = allNewMarkedInventories.filter(i => i.inventoryType === 'PACK');
      if (newMarkedDevices.length) {
        assignedDevices = [...assignedDevices, ...newMarkedDevices];
      }
      if (newMarkedPacks.length) {
        assignedPacks = [...assignedPacks, ...newMarkedPacks];
      }
    }
    if (removeDeviceFromEventInd !== -1) {
      assignedDevices = assignedDevices.filter(inv => removeDeviceFromEvent[removeDeviceFromEventInd].inventoryIds.indexOf(inv.id) === -1);
      assignedPacks = assignedPacks.filter(inv => removeDeviceFromEvent[removeDeviceFromEventInd].inventoryIds.indexOf(inv.id) === -1);
    }
    // Set default properties
    assignedDevices.forEach(d => {
      d.inventoryStatus = 'USED';
      d.eventPrice = 0;
      d.noCharge = false;
    });
    assignedPacks.forEach(d => {
      d.inventoryStatus = 'USED';
      d.eventPrice = 0;
      d.noCharge = false;
    });

    return {
      inventories: assignedDevices,
      packs: assignedPacks
    };
  }

  /** Use search parameters:
   * searchText = includes query: name | catalog.referenceNumber | lot | tags
   */
  async getSimilarInventoryData(
    eventId: string,
    searchText: string = '',
    toExclude: SimilarInventoryInfo[] = []
  ): Promise<SimilarInventoryInfo[]> {
    const searchQuery: string = searchText.toLowerCase();
    const getAllValues = this.offlineService.offlineInventories;

    const eventAssignments: IEventAssignmentsDB = this.offlineService.offlineEventAssignments.get(eventId);

    const eventRepresentativeId = eventAssignments?.event?.representative?.id?.length ? eventAssignments?.event?.representative?.id : null;
    const result: SimilarInventoryInfo[] = [];
    const changesArr = this.offlineService.userChanges.markUsedInventory;
    let newMarkedIds: string[] = [];
    if (changesArr?.length) {
      newMarkedIds = changesArr.reduce((common: string[], changes: IDUTChanges) => [...common, ...changes.inventoryIds], []);
    }
    getAllValues.forEach(inv => {
      // result nor more than 50
      if (result.length > 50) {
        return;
      }
      // If item matches base requirements
      if (
        // If item matches base requirements
        (inv.inventoryType === 'DEVICE' || inv.inventoryType === 'PACK') &&
        inv.state === 'ACTIVE' &&
        inv.transfer === null &&
        (inv.custody?.id?.length ? inv.custody?.id : null) === eventRepresentativeId &&
        (inv.holdStatus === null || inv.holdStatus === 'READY_FOR_SALE') &&
        inv.event === null &&
        newMarkedIds.indexOf(inv.id) === -1 &&
        !inv.markedInEvent &&
        !inv.isInPack
      ) {
        if (this.isInvMatchesSearchQuery(inv, searchQuery)) {
          const similarInd: number = this.isSimilarInventoryExist(result, inv);
          if (similarInd !== -1) {
            result[similarInd].count += 1;
            result[similarInd].specificInventories.push(inv.id);
          } else {
            result.push({
              barcode: inv.barcode,
              billable: inv.billable,
              catalogId: inv.catalog.id,
              count: 1,
              custodyId: inv.custody?.id ?? null,
              custodyName: inv.custody?.name,
              locationId: inv.location?.id ?? null,
              inventoryDeviceType: inv.inventoryDeviceType,
              expirationStatus: inv.expirationStatus,
              inventoryType: inv.inventoryType,
              lotNumber: inv.lotNumber,
              name: inv.name,
              parentCatalogId: inv.parentInventory?.catalog?.id ?? null,
              parentReferenceNumber: inv.parentInventory?.catalog?.referenceNumber ?? null,
              referenceNumber: inv.catalog?.referenceNumber,
              serialNumber: inv.serialNumber,
              specificInventories: [inv.id],
              tags: inv?.tags ? JSON.stringify(inv.tags) : null,
              udi: inv.udi
            });
          }
        }
      }
    });

    // Hide already selected items
    if (toExclude?.length) {
      toExclude.forEach(s => {
        const isExist = result.findIndex(
          i =>
            i.billable === s.billable &&
            i.catalogId === s.catalogId &&
            i.custodyId === s.custodyId &&
            i.locationId === s.locationId &&
            i.inventoryDeviceType === s.inventoryDeviceType &&
            i.expirationStatus === s.expirationStatus &&
            i.lotNumber === s.lotNumber &&
            i.name === s.name &&
            i.parentCatalogId === s.parentCatalogId &&
            i.serialNumber === s.serialNumber &&
            i.tags === s.tags &&
            i.udi === s.udi
        );
        if (isExist !== -1) {
          result.splice(isExist, 1);
        }
      });
    }
    return result;
  }

  isSimilarInventoryExist(similar: SimilarInventoryInfo[], inv: InventoryModel): number {
    return similar.findIndex(
      s =>
        s.billable === inv.billable &&
        s.catalogId === inv.catalog.id &&
        s.custodyId === (inv.custody?.id ?? null) &&
        s.locationId === (inv.location?.id ?? null) &&
        s.inventoryDeviceType === inv.inventoryDeviceType &&
        s.expirationStatus === inv.expirationStatus &&
        s.inventoryType === inv.inventoryType &&
        s.lotNumber === inv.lotNumber &&
        s.name === inv.name &&
        s.parentCatalogId === (inv.parentInventory?.catalog?.id ?? null) &&
        s.serialNumber === inv.serialNumber &&
        // Example backend response "[\"1111111\",\"222222\",\"3333333\"]", should use JSON.stringify(JSON.parse())
        JSON.stringify(JSON.parse(s.tags)) === JSON.stringify(inv.tags) &&
        s.udi === inv.udi
    );
  }

  isInvMatchesSearchQuery(inv: InventoryModel, searchQuery: string = ''): boolean {
    if (!searchQuery?.length || searchQuery === '') {
      return true;
    }
    const q = searchQuery.toLowerCase();
    return (
      inv.name?.toLowerCase().includes(q) ||
      inv.udi?.toLowerCase().includes(q) ||
      inv.serialNumber?.toLowerCase().includes(q) ||
      inv.lotNumber?.toLowerCase().includes(q) ||
      inv.catalog.referenceNumber?.toLowerCase()?.includes(q) ||
      JSON.stringify(inv.tags)?.includes(q)
    );
  }

  private getIdFromRequestUrl(
    url: string,
    separator: '/events/' | '/inventories/' | '/events/mobile/' | '/inventories/search' = '/events/'
  ): string {
    const a: string = url.split(separator)[1];
    return a.split('/')[0];
  }

  private getAllInventories(request: HttpRequest<unknown>): PageableModel<InventoryModel> {
    const offlineInventories = this.offlineService.offlineInventories;
    //@ts-ignore
    const searchQuery: string = request?.params?.map?.get('searchText')?.length
      ? //@ts-ignore
        request.params.map.get('searchText')[0]?.toLowerCase()
      : '';
    const result: PageableModel<InventoryModel> = new PageableModel();
    result.totalElements = this.offlineService.offlineInventories.size;
    if (searchQuery?.length) {
      offlineInventories.forEach(i => {
        if (result.content.length < 100 && this.isInvMatchesSearchQuery(i, searchQuery)) {
          result.content.push(i);
        }
      });
    } else {
      result.content = Array.from(offlineInventories.values());
    }
    if (result.content.length > 100) {
      result.content.length = 100;
    }
    return result;
  }

  private getEventsPageable(request: HttpRequest<unknown>): PageableModel<EventModel> {
    const getAllValues = this.offlineService.offlineEventAssignments;
    const p: PageableModel<EventModel> = new PageableModel();
    //@ts-ignore
    const searchQuery: string = request?.params?.map?.get('eventName')?.length ? request.params.map.get('eventName')[0]?.toLowerCase() : '';
    getAllValues.forEach(i => {
      if (i.event?.name.toLowerCase().includes(searchQuery)) {
        p.content.push(i.event);
      }
    });
    p.totalElements = p.content.length;
    return p;
  }
}
