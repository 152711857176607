import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@guards/auth/auth.guard';
import { LayoutsComponent } from '@shared/components/global/layouts/layouts.component';
import { PermissionsGuard } from '@guards/permissions/permissions.guard';
import { OfflinePageComponent } from '@shared/standalone/pages/offline-page/offline-page.component';
import { isMobileDevice } from '@shared/utils/is-device';
import { MaintenanceGuard } from '@guards/maintenance/maintenance.guard';
import { QuicklinkStrategy } from 'ngx-quicklink';

const routes: Routes = [
  {
    path: '',
    canActivate: [MaintenanceGuard],
    loadChildren: () => import('../features/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    canActivate: [MaintenanceGuard],
    loadChildren: () => import('../features/non-auth/non-auth.module').then(m => m.NonAuthModule),
    data: {
      preload: false
    }
  },
  {
    path: '',
    component: LayoutsComponent,
    canActivate: [AuthGuard, PermissionsGuard, MaintenanceGuard],
    children: [
      {
        path: 'dashboard',
        loadComponent: () => import('../features/dashboard/pages/dashboard.component').then(c => c.DashboardComponent)
      },
      {
        path: 'inventory',
        data: {
          breadcrumb: {
            label: 'Inventory',
            disable: true
          }
        },
        loadChildren: () => import('../features/inventory/inventory.routing').then(m => m.InventoryRouting)
      },
      {
        path: 'movement',
        data: {
          breadcrumb: {
            label: 'Movements',
            disable: true
          }
        },
        loadChildren: () => import('../features/movement/movement.routing').then(m => m.MovementRouting)
      },
      {
        path: 'directory',
        data: {
          breadcrumb: {
            label: 'Directory',
            disable: true
          }
        },
        loadChildren: () => import('../features/directory/directory.routing').then(m => m.DirectoryRouting)
      },
      {
        path: 'events',
        data: {
          breadcrumb: 'Events'
        },
        loadChildren: () => import('../features/events/events.routing').then(m => m.EventsRouting)
      },
      {
        path: 'notifications',
        loadChildren: () => import('../features/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'users',
        data: {
          breadcrumb: 'Users'
        },
        loadChildren: () => import('../features/users/users.routing').then(m => m.UsersRouting)
      },
      {
        path: 'settings',
        loadChildren: () => import('../features/settings/settings.module').then(m => m.SettingsModule)
      },
      {
        path: 'integrations',
        loadComponent: () => import('../features/integrations/pages/integrations.component').then(c => c.IntegrationsComponent)
      },
      {
        path: 'statistics',
        canActivate: [PermissionsGuard],
        loadComponent: () =>
          import('../features/statistics/pages/list-statistics/list-statistics.component').then(c => c.ListStatisticsComponent)
      },
      {
        path: 'statistics/:id',
        canActivate: [PermissionsGuard],
        loadComponent: () =>
          import('../features/statistics/pages/statistic-detail/statistic-detail.component').then(c => c.StatisticDetailComponent),
        data: {
          preload: false
        }
      },
      {
        path: 'app-info',
        loadComponent: () => import('@shared/standalone/pages/app-info/app-info.component').then(c => c.AppInfoComponent),
        data: {
          preload: false
        }
      }
    ]
  },
  {
    path: 'offline',
    component: OfflinePageComponent
  },
  {
    path: 'load-testing',
    loadComponent: () => import('@shared/standalone/pages/load-testing/load-testing.component').then(c => c.LoadTestingComponent),
    canActivate: [AuthGuard],
    data: {
      preload: false
    }
  },
  {
    path: 'server-error',
    loadComponent: () =>
      import('@shared/standalone/pages/server-error-page/server-error-page.component').then(c => c.ServerErrorPageComponent),
    data: {
      preload: false
    }
  },
  {
    path: 'maintenance',
    loadComponent: () => import('@shared/standalone/pages/maintenance/maintenance.component').then(c => c.MaintenanceComponent),
    data: {
      preload: false
    }
  },
  {
    path: 'organization-tree',
    loadComponent: () =>
      import('@shared/standalone/pages/organization-tree/organization-tree.component').then(c => c.OrganizationTreeComponent),
    canActivate: [AuthGuard, MaintenanceGuard],
    data: {
      preload: false
    }
  },
  {
    path: '**',
    loadComponent: () => import('@shared/standalone/pages/page-not-found/page-not-found.component').then(c => c.PageNotFoundComponent),
    data: {
      preload: false
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: QuicklinkStrategy,
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: isMobileDevice() ? 'disabled' : 'enabled'
    })
  ]
})
export class CoreRoutingModule {}
