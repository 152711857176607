<div class="container" data-layout="row" data-layout-align="center center">
  <div
    data-layout="row"
    data-layout-xs="column"
    data-layout-lt-md="column"
    data-layout-align-xs="start stretch"
    data-layout-gap="48px"
    data-layout-gap-xs="0"
  >
    <div
      data-layout="column"
      data-layout-align="start center"
      data-layout-lt-md="column"
      data-layout-align-lt-md="center center"
      data-layout-align-xs="start stretch"
    >
      <span class="offlineTitle">{{ 'shared.components.offline' | language }}</span>
      <span class="oopsTitle">{{ 'shared.components.offlineCaption' | language }}</span>
    </div>
    <span class="connectSxIcon" data-layout-align="center center"></span>
  </div>
</div>
