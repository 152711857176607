import { Location } from '@angular/common';
import { Component, inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ScreenTitleDecorator } from '@shared/decorators/screen-title.decorator';
import { ConnectionStatus, Network } from '@capacitor/network';
import { SharedModule } from '@shared/shared.module';
import { Router } from '@angular/router';
import { GlobalStoreService } from '@services/internal/global-store.service';

@Component({
  selector: 'app-offline-page',
  templateUrl: './offline-page.component.html',
  styleUrls: ['./offline-page.component.scss'],
  imports: [SharedModule]
})
@ScreenTitleDecorator('shared.pageTitles.offline')
export class OfflinePageComponent implements OnInit, OnDestroy {
  private location = inject(Location);
  private zone = inject(NgZone);
  private router = inject(Router);

  ngOnInit() {
    Network.getStatus().then(status => this.checkStatus(status.connected));
    Network.addListener('networkStatusChange', (status: ConnectionStatus) => {
      this.checkStatus(status.connected);
    });
  }

  private checkStatus(status: boolean): void {
    this.zone.run(() => {
      if (status) {
        if (GlobalStoreService.getPlatform() === 'web') {
          this.location.back();
        } else {
          this.router.navigate(['/dashboard']);
        }
      }
    });
  }

  ngOnDestroy() {
    Network.removeAllListeners();
  }
}
